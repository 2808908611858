import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import scrollTo from "gatsby-plugin-smoothscroll"

export default function ImageCover({
  image,
  heading,
  para,
  section,
  truedat,
  MainCover,
  center,
  arrow,
}) {
  //const length = para?.length || 1

  return (
    <SectionStyles truedat={truedat} MainCover={MainCover} center={center}>
      <div id="media-cover">
        <GatsbyImage image={image} alt={`${heading} Wines`} />
      </div>
      <div className="container">
        <h2 data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
          {heading}
        </h2>

        <p data-sal="zoom-out" data-sal-delay="300" data-sal-easing="ease">
          {para}
        </p>
        {arrow === false ? null : (
          <ExpandMoreIcon
            className={"icon text-white w-[40px] h-[40px]"}
            onClick={() => scrollTo(section)}
          />
        )}
      </div>
    </SectionStyles>
  )
}

export const SectionStyles = styled.section`
  .gatsby-image-wrapper {
    filter: brightness(80%);

    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  overflow: hidden;

  #media-cover {
    overflow: hidden;
    height: 400px;
    object-fit: cover;
  }
  .icon {
    position: absolute;
    bottom: 20px;
    color: white;
    font-size: 40px;
    cursor: pointer;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .container {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    color: white;
    max-width: 680px;
    position: absolute;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
    & h2 {
      text-align: center;
      font-weight: bold !important;
    }
    & p {
      text-align: justify;
      opacity: 1 !important;
      white-space: pre-wrap;
      text-align: ${props => (props.center === true ? `center` : `left`)};
    }
  }
  position: relative;
  .container h2 {
    margin: 0 !important;
    opacity: 1;
    margin-top: 0;
    color: var(--white);
    font-size: var(--coverImage);
    font-family: var(--ff-heading);
    padding: 0;
  }

  .container p {
    color: var(--white);
    font-size: 24px;
    font-weight: 400;
    font-family: var(--ff-heading);
  }

  @media only screen and (max-width: 900px) {
    height: 400px;

    .gatsby-image-wrapper {
      height: 400px;
    }

    #media-cover {
      height: 400px;
      max-width: 100% !important;
      width: 100% !important;
      border-radius: 0;
    }
    .container {
      margin-top: 3rem;
      & h2 {
        font-size: 2.8rem;
        margin-top: -2rem !important;
      }
      & p {
        font-weight: 400;
      }
    }
    .container {
      width: 70%;
      padding: 0;
      & h2 {
        font-size: 2rem;
        line-height: 1.3;
      }
      & p {
        font-size: 1.5rem;
        letter-spacing: 3.5px;
      }
    }
  }
`
