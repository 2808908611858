import React from "react"
import Layout from "../components/layout"
import ImageCover from "../components/ImageCover"
import { graphql } from "gatsby"
//import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Seo from "../components/seo"

const Wrapper = styled.section`
  .logo {
    margin: 30px;
  }
  max-width: 980px;
  margin: 0 auto;
  padding: 0 40px 40px 40px;
  .markedContent {
    margin-top: 40px;
  }
  h1 {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 400px) {
    .logo {
      margin: 15px;
      margin-top: 20px;
    }
  }
`

const MainWrapper = styled.div`
  h2 {
    margin: 0 auto;
    text-align: center;
    padding: 40px;
    font-size: 30px;
  }

  #Partners {
    margin-top: 4rem;
  }

  #FeaturedPartners {
    max-width: 1200px;
    margin: 0 auto;
  }
`

const StyledImage = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  //gap: 40px;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-bottom: 30px;
`

const usPartners = ({ data }) => {
  return (
    <Layout relative={false}>
      <Seo title="Meet Some Of Our Partners" />
      <MainWrapper>
        <ImageCover
          image={
            data.MyQuery.Cover.CoverImage.localFile.childImageSharp
              .gatsbyImageData
          }
          heading={data.MyQuery.Cover.Paragraph}
          section={"#Partners"}
          third
          MainCover={true}
        />

        <Wrapper id="Partners">
          <StyledImage>
            {data.Partners.Partners.map((logo, index) => (
              <div key={index}>
                <GatsbyImage
                  image={logo.localFile.childImageSharp.gatsbyImageData}
                  alt="Bruno Lafon Selection Partners"
                  className="logo"
                />
              </div>
            ))}
          </StyledImage>
        </Wrapper>
      </MainWrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    Partners: strapiPartners {
      Partners {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 55, quality: 60)
          }
        }
      }
    }
    MyQuery: strapiPartners {
      Cover {
        Headline
        Paragraph
        FullHeight
        CoverImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 60)
            }
          }
        }
      }
    }
  }
`

export default usPartners
